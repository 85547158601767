import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IUnitFull } from '../../modules/monitoring/modules/unit/models/unit';
import { lastValueFrom, Observable, of } from 'rxjs';
import { ConfigService, IConfig } from '@shared/services/config.service';
import { ApiRepository } from '@shared/repositories/api.repository';
import { IPermissions } from '@store/types';
import { cloneDeep } from 'lodash';
import { map } from 'rxjs/operators';

export interface IUnitsState {
	allowedUnits: IUnitFull[];
	allUnits: IUnitFull[]; //все юниты
	mapUnitsById:  Map<number, IUnitFull>;
	mapUnitsByLocationId:  Map<number, IUnitFull[]>;
	mapUnitsByInventory:  Map<string, IUnitFull>;
}

export const DefaultUnitState: IUnitsState =  {
	allowedUnits: [],
	allUnits: [],
	mapUnitsById:  new Map<number, IUnitFull>,
	mapUnitsByLocationId:  new Map<number, IUnitFull[]>,
	mapUnitsByInventory:  new Map<string, IUnitFull>,
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'unitsStore'})
export class UnitsStore extends Store<IUnitsState> {
	private _config: Pick<IConfig, 'rootLocationId' | 'ascueRootLocationId'>;

	constructor(
		private _configService: ConfigService,
		private _apiService: ApiRepository,
	) {
		super(DefaultUnitState);
	}

	public init(permission: IPermissions): Observable<IUnitsState> {
		this._config = this._configService.getConfig([
			'rootLocationId',
			'ascueRootLocationId',
		]);
		const units$: Observable<IUnitFull[]> = this._config['rootLocationId']
			? this._apiService.getAllUnitsLite(this._config['rootLocationId'])
			: of([]);

		return units$.pipe(
			map((res) => {
				const data = this.getUnitsState(permission, res);
				this.update(data)
				return data;
			})
		)
	}

	private getUnitsState(permission: IPermissions, units: IUnitFull[]): IUnitsState {
		const allowedUnits: IUnitFull[] = this.filterUnitsByPermission(permission.units, units);
		const mapUnitsById: Map<number, IUnitFull> =  new Map<number, IUnitFull>;
		const mapUnitsByLocationId: Map<number, IUnitFull[]> = new Map<number, IUnitFull[]>;
		const mapUnitsByInventory: Map<string, IUnitFull> = new Map<string, IUnitFull>;

		units.forEach((unit) => {
			mapUnitsById.set(unit.id as number, unit);
			mapUnitsByInventory.set(unit.inventoryNumber, unit);
			this.setUnitByLocation(unit, mapUnitsByLocationId);
		});

		return {
			allowedUnits: allowedUnits,
			allUnits: units,
			mapUnitsById: mapUnitsById,
			mapUnitsByLocationId: mapUnitsByLocationId,
			mapUnitsByInventory: mapUnitsByInventory,
		}
	}

	private setUnitByLocation(unit: IUnitFull, mapUnitsByLocationId: Map<number, IUnitFull[]>): void {
		const locationId = unit.locationId || unit.location?.id;

		if (locationId === null || locationId === undefined) {
			return;
		}

		const units = mapUnitsByLocationId.get(locationId);

		if (!units) {
			mapUnitsByLocationId.set(locationId, [unit]);
		} else {
			units.push(unit);
		}
	}

	private filterUnitsByPermission(allowedUnitsIds: number[], units: IUnitFull[]): IUnitFull[] {
		const set = new Set();

		allowedUnitsIds.forEach((id: number) => set.add(id));
		return units.filter((unit) => set.has(unit.id));
	}
}
