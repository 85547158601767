import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IUnitsState, UnitsStore } from '@store/units-store/units.store';
import { IUnitFull } from '../../modules/monitoring/modules/unit/models/unit';

@Injectable({
	providedIn: 'root',
})
export class UnitsSelector extends Query<IUnitsState> {
	constructor(
		protected store: UnitsStore,
	) {
		super(store);
	}

	public getAllowedUnitsByInventoryNumber(inventoryNumber: string): IUnitFull[] {
		return this.getValue().allowedUnits
			.filter((unit) => !!unit.inventoryNumber && unit.inventoryNumber.includes(inventoryNumber));
	}

	public getAllUnits(): IUnitFull[] {
		return this.getValue().allUnits;
	}

	public getAllowedUnits(): IUnitFull[] {
		return this.getValue().allowedUnits;
	}

	public getUnitById(id: number): IUnitFull {
		return this.getValue().mapUnitsById.get(id);
	}

	public getUnitsMap(): Map<number, IUnitFull> {
		return this.getValue().mapUnitsById;
	}

	public getUnitByInventoryNumber(number: string): IUnitFull {
		return this.getValue().mapUnitsByInventory.get(number);
	}

	public getUnitsByLocation(locationId: number): IUnitFull[] {
		const units = this.getValue().mapUnitsByLocationId.get(locationId);
		return !!units ? units : [];
	}

	public getUnitsByLocations(locationIds: number[]): IUnitFull[] {
		return locationIds.reduce((acc: IUnitFull[], locationId) => {
			const units = this.getUnitsByLocation(locationId);

			return units?.length ? acc.concat(units) : acc;
		}, []);
	}
}
